/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import {
  setBrandingFromUrl,
  ThemeContext,
  useIsLoggedIn,
  useOverTheDeviceLimitSelector,
  IConfigurationModel,
} from "@nf/common";
import React, { useContext, useEffect } from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { ComponentHelper } from "../../helpers";
import { usePreloadStylesheet } from "../../hooks";
import { PAYMENT_PARAM } from "constants/param";
import type { PageMetadataKeys } from "components/SEO/pageMetadata";

const OverDeviceLimitModal = dynamic(
  () => import("components/OverDeviceLimitModal")
);
const BaseScreen = dynamic(() => import("../BaseScreen"));

const ReceiptModal = dynamic(() => import("components/ReciptModal"));

const SEO = dynamic(() => import("components/SEO/SEO"));

type IMainScreenParams = {
  id: string;
  [PAYMENT_PARAM]?: string;
};

type TMainScreenProps = {
  configuration: IConfigurationModel;
  screenData: any;
  componentData: any;
};

export const MainScreen = (props: TMainScreenProps) => {
  const router = useRouter();
  const params = router.query as unknown as IMainScreenParams;
  const screenKey = params.id as PageMetadataKeys;
  const paymentStatus = params[PAYMENT_PARAM];
  const isLoggedIn = useIsLoggedIn();
  const overDeviceLimit = useOverTheDeviceLimitSelector();
  const channelContext = useContext(ThemeContext);

  usePreloadStylesheet();

  useEffect(() => {
    setBrandingFromUrl(params.id, channelContext);

    return () => {
      // Restoring default branding
      channelContext?.onBrandingChange?.();
    };
  }, [params.id, channelContext]);

  const renderContent = (): React.ReactNode | null => {
    return props?.screenData
      ? ComponentHelper.renderComponents(
          props?.screenData,
          isLoggedIn,
          overDeviceLimit,
          props.componentData
        )
      : null;
  };

  return (
    <>
      <SEO screenKey={screenKey} configuration={props.configuration} />
      <BaseScreen>
        {() => (
          <>
            {renderContent()}
            {overDeviceLimit && <OverDeviceLimitModal />}
            {paymentStatus && <ReceiptModal status={paymentStatus} />}
          </>
        )}
      </BaseScreen>
    </>
  );
};

export default MainScreen;
