/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { CellType, Orientation } from "@nf/common";
import React from "react";
import dynamic from "next/dynamic";

const GridComponent = dynamic(() => import("./components/GridComponent"));

const ListComponentFrame = dynamic(
  () => import("./components/ListComponentFrame")
);

const ListComponentHighlight = dynamic(
  () => import("./components/ListComponentHighlight")
);

const ListComponentHorizontal = dynamic(
  () => import("./components/ListComponentHorizontal/ListComponentHorizontal")
);

const ListComponentPromo = dynamic(
  () => import("./components/ListComponentPromo")
);

const ListComponentVertical = dynamic(
  () => import("./components/ListComponentVertical")
);

import { IListComponentProps } from "./types";

export const ListComponent = React.memo(
  ({
    readOnly = false,
    ready = true,
    component,
    loading,
  }: IListComponentProps) => {
    switch (component.Orientation) {
      case Orientation.Grid:
        return (
          <GridComponent
            component={component}
            loading={loading}
            readOnly={readOnly}
          />
        );
      case Orientation.Vertical:
        return (
          <ListComponentVertical
            component={component}
            loading={loading}
            ready={ready}
            readOnly={readOnly}
          />
        );
    }
    switch (component.CellType) {
      case CellType.Highlights:
        return (
          <ListComponentHighlight
            component={component}
            loading={loading}
            ready={ready}
            readOnly={readOnly}
          />
        );
      case CellType.Promo:
        return (
          <ListComponentPromo
            component={component}
            loading={loading}
            ready={ready}
            readOnly={readOnly}
          />
        );
      case CellType.Frame:
      case CellType.FrameLarge:
        return (
          <ListComponentFrame
            component={component}
            loading={loading}
            ready={ready}
            readOnly={readOnly}
          />
        );
      default:
        return (
          <ListComponentHorizontal
            component={component}
            loading={loading}
            ready={ready}
            readOnly={readOnly}
          />
        );
    }
  }
);

export default ListComponent;
