import { IMediaModel } from "@nf/common";

type TMediaSEOField = {
  media: IMediaModel;
  className?: string;
};

export const MediaSEOField = ({
  media,
  className,
}: TMediaSEOField): JSX.Element => {
  // nosemgrep
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{ __html: media?.Article || "" }}
    />
  );
};
