/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  CellType,
  ComponentType,
  IListComponentModel,
  IMediaModel,
  Orientation,
} from "@nf/common";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import { useMemo } from "react";

import styles from "./MediaExtraMaterials.module.scss";

const ListComponent = dynamic(
  () => import("components/Configuration/ListComponent/ListComponent")
);

interface MediaContentListProps {
  title: string;
  mediaList: IMediaModel[];
}

export const MediaExtraMaterials = ({
  title,
  mediaList,
}: MediaContentListProps) => {
  const { t } = useTranslation("translations");
  const component = useMemo(
    () => ({
      Id: t(title, ""),
      ComponentTypeCode: ComponentType.List,
      MediaList: mediaList,
      CellType: CellType.Frame,
      Orientation: Orientation.Horizontal,
      VisibleItemsCount: 4,
      Title: t(title, ""),
    }),
    [mediaList]
  );

  return (
    <div className={styles.container}>
      <ListComponent component={component as IListComponentModel} />
    </div>
  );
};
