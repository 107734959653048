/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import styles from "./DotsLoader.module.scss";
import classnames from "classnames";

export interface IDotsLoaderProps {
  width?: number;
}

const RADIUS = 2.5;
const CY = 12;
const CX = RADIUS + 1.5;

export const DotsLoader = ({ width = 140 }: IDotsLoaderProps) => {
  return (
    <div className={styles.dotsLoader}>
      <svg
        width={width}
        height={width}
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <style></style>
        <circle className={styles.first_dot} cx={CX} cy={CY} r={RADIUS} />
        <circle
          className={classnames(styles.first_dot, styles.second_dot)}
          cx={CX * 3}
          cy={CY}
          r={RADIUS}
        />
        <circle
          className={classnames(styles.first_dot, styles.third_dot)}
          cx={CX * 5}
          cy={CY}
          r={RADIUS}
        />
      </svg>
    </div>
  );
};

export default DotsLoader;
