/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { IMediaModel } from "@nf/common";
import cx from "classnames";
import dynamic from "next/dynamic";

const MediaDetailsDescriptionList = dynamic(
  () => import("../MediaDetailsDescriptionList")
);

import styles from "./MediaDetailsBottomContent.module.scss";
import { MediaExtraMaterials } from "../MediaExtraMaterials/MediaExtraMaterials";
import { MediaSEOField } from "../MediaSEOField/MediaSEOField";

export interface IMediaDetailsBottomContentProps {
  media: IMediaModel;
  extraMaterials?: IMediaModel[];
  relatedMaterials?: IMediaModel[];
  children?: JSX.Element;
  isCollection?: boolean;
  className?: string;
  descrClass?: string;
}

export const MediaDetailsBottomContent = ({
  children,
  media,
  extraMaterials,
  relatedMaterials,
  isCollection,
  className,
  descrClass,
}: IMediaDetailsBottomContentProps) => {
  return (
    <>
      <div className={cx(styles.MediaDetailsBottomContent, className)}>
        <p className={cx(styles.descriptionText, descrClass)}>
          {!media.Description ? media.LongDescription : media.Description}
        </p>
        {children}
        {!isCollection ? <MediaDetailsDescriptionList media={media} /> : null}
      </div>

      {!!extraMaterials?.length && (
        <MediaExtraMaterials
          title="web_extramaterial"
          mediaList={extraMaterials}
        />
      )}

      {!!relatedMaterials?.length && (
        <MediaExtraMaterials
          title="web_asset_page_related_movies"
          mediaList={relatedMaterials}
        />
      )}

      {media?.Article && !isCollection && (
        <div className={styles.MediaDetailsBottomContent}>
          <MediaSEOField media={media} className={styles.article} />
        </div>
      )}
    </>
  );
};

export default MediaDetailsBottomContent;
