/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import { Dispatch } from "redux";
import { IAppState, MediaStore } from "@nf/common";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import dynamic from "next/dynamic";
import {
  IGridComponentDispatchProps,
  IGridComponentOwnProps,
  IGridComponentStateProps,
} from "./GridComponent";

const GridComponentDefinition = dynamic(() => import("./GridComponent"));

const mapStateToProps = (state: IAppState, props: IGridComponentOwnProps) => {
  return {
    source: props.component.SourceId
      ? state.media.mediaList?.[props.component.SourceId]
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getDataSource: (sourceId: number) => {
    return dispatch(
      MediaStore.Actions.getMediaList({
        MediaListId: sourceId,
        IncludeCategories: false,
        IncludeImages: true,
        IncludeMedia: false,
        PageSize: 12,
        PageNumber: 1,
      })
    );
  },
  getDataSourceCancel: () => dispatch({ type: "nothing" }),
});

export const GridComponent = connect<
  IGridComponentStateProps,
  IGridComponentDispatchProps,
  IGridComponentOwnProps,
  IAppState
>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(GridComponentDefinition));

export default GridComponent;
