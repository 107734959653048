/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React, { useEffect } from "react";
import { dispatch, UserStore, useUserSubprofilesSelector } from "@nf/common";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";

import type {
  IAddProfileProps,
  IProfileCircleProps,
} from "components/Subprofiles";

import styles from "./SettingsProfiles.module.scss";

const ParentalControl = dynamic(() => import("./components/ParentalControl"));
const ActionsHandler = dynamic(() => import("components/ActionsHandler"));
const AddProfile = dynamic<IAddProfileProps>(() =>
  import("components/Subprofiles/ProfileCircle").then((mod) => mod.AddProfile)
);
const ProfileCircle = dynamic<IProfileCircleProps>(() =>
  import("components/Subprofiles/ProfileCircle").then(
    (mod) => mod.ProfileCircle
  )
);

export const SettingsProfiles = () => {
  const { t } = useTranslation("translations");

  const {
    Data: subprofilesState,
    IsProcessing: profilesLoading,
    Error: profilesError,
  } = useUserSubprofilesSelector();

  const subprofilesList = subprofilesState?.subprofiles;
  const currentSubprofile = subprofilesState?.currentSubprofile;
  const pinCodeState = subprofilesState?.auth;

  const isKidProfileAvailable = subprofilesState?.subprofiles?.some(
    (profile) => profile.Child
  );

  useEffect(() => {
    !subprofilesList?.length &&
      !profilesError &&
      !profilesLoading &&
      dispatch(UserStore.Actions.getUserSubprofiles());
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.Container}>
      <p className={styles.Description}>{t("web_subprofiles_description")}</p>
      <ActionsHandler
        isLoading={profilesLoading}
        error={profilesError || pinCodeState?.Error}
      >
        <>
          <div className={styles.ProfileList}>
            {subprofilesList?.map((subprofile) => (
              <ProfileCircle
                editButton={subprofilesState?.currentSubprofile?.Owner}
                subprofile={subprofile}
                key={`profile-circle-${subprofile.UserId}`}
                ellipseText
              />
            ))}
            <AddProfile subprofilesState={subprofilesState} />
          </div>
          {isKidProfileAvailable && currentSubprofile?.Owner && (
            <ParentalControl
              isPinAvailable={!!pinCodeState?.PinCodeId}
              pinCodeState={pinCodeState}
            />
          )}
        </>
      </ActionsHandler>
    </div>
  );
};

export default SettingsProfiles;
