/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import React from "react";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { isAndroid, isIOS } from "react-device-detect";

import styles from "./MediaDetailsPlayInfo.module.scss";

const MediaButton = dynamic(() => import("components/MediaButton"));

const OfferButton = dynamic(() => import("components/OfferButton"));

export interface IMediaDetailsPlayInfoProps {
  canWatch?: boolean;
  title?: string;
}

export const MediaDetailsPlayInfo = ({
  canWatch,
  title,
}: IMediaDetailsPlayInfoProps): JSX.Element => {
  const { t } = useTranslation("translations");
  const { push } = useRouter();
  const noSubComponents = !canWatch && (
    <div className={title ? styles.desktop : styles.nodata}>
      <OfferButton className={styles.desktop} />
    </div>
  );

  const redirectToProperApplication = () => {
    if (isAndroid) {
      push(
        "https://play.google.com/store/apps/details?id=com.nordiskfilmplus.android"
      );
    } else if (isIOS) {
      push("https://apps.apple.com/app/nordisk-film/id1620553745");
    }
  };

  const downloadAppComponents = (
    <div className={styles.mobile}>
      <MediaButton
        className={styles.button}
        variant="primary"
        onClick={redirectToProperApplication}
      >
        {t("web_asset_page_download_app", "")}
      </MediaButton>
      <p>{t("web_asset_page_download_app_text", "")}</p>
    </div>
  );

  return (
    <div className={styles.downloadInfoContainer}>
      {!canWatch
        ? noSubComponents
        : (isAndroid || isIOS) && downloadAppComponents}
    </div>
  );
};

export default MediaDetailsPlayInfo;
