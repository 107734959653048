/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
import {
  IApplicationMenuScreenModel,
  ScreenType,
  IApplicationMenuItemComponentModel,
} from "@nf/common";
import React from "react";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const Menu = dynamic(() => import("../Menu/Menu"));

const ApplicationMenuItem = dynamic(() => import("../ApplicationMenuItem"));

import styles from "./ApplicationMenu.module.scss";

export interface IApplicationMenuProps {
  screen?: IApplicationMenuScreenModel;
  isChild?: boolean;
}

export const ApplicationMenu = ({ screen, isChild }: IApplicationMenuProps) => {
  const router = useRouter();
  const params = router.query as any;

  if (!screen?.Components) {
    return null;
  }

  const componentsList = screen?.Components?.filter(
    (component: IApplicationMenuItemComponentModel) => {
      if (!component.IsVisible) {
        return false;
      }

      if (component.IsAgeRestricted && isChild) {
        return false;
      }

      return component.Action?.ScreenTypeCode !== ScreenType.Search;
    }
  );

  return (
    <div className={styles.menu}>
      <Menu
        mode="horizontal"
        selectedKeys={[params.id || ""]}
        triggerSubMenuAction="click"
        tabIndex={-1}
      >
        {componentsList.map((component) => {
          return (
            <ApplicationMenuItem key={component.Id} component={component} />
          );
        })}
      </Menu>
    </div>
  );
};

export default ApplicationMenu;
